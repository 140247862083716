import {Injectable, EventEmitter} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {PubNubAngular} from 'pubnub-angular2';

@Injectable({
  providedIn: 'root'
})
export class EventDistributerService {

  private listener: Array<any> = new Array<any>();

  constructor(private pubnub: PubNubAngular) {
    this.pubnub.init({
      publishKey: 'pub-c-9ef8635f-8eae-4a07-adb8-515edd926442',
      subscribeKey: 'sub-c-2ebd149c-7e1c-11e5-9e96-02ee2ddab7fe',
      ssl: true
    });
  }

  init(spui: string): void {

    const channels = ['Speech Intent Recognizer - ' + spui, 'TransferCall - ' + spui, 'TriggerSearch - ' + spui, 'AddNotes - ' + spui, 'Automation - ' + spui,];

    this.pubnub.subscribe({
      channels: channels,
      triggerEvents: ['message']
    });

    this.pubnub.getMessage(channels, (event) => {
      console.log(event);
      this.listener.forEach((listener: Subscriber<any>) => {
        listener.next(event);
      });
    });

  }


  listen(): Observable<any> {

    const observable = new Observable<any>((observer) => {
      this.listener.push(observer);
    });

    return observable;
  }

  emit(event: any): void {

    this.listener.forEach((listener) => {
      listener.next(event);
    });

  }

}
