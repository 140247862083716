import {Component, OnInit} from '@angular/core';
import {EventDistributerService} from '../../../event-distributer.service';
import {ActivatedRoute} from '@angular/router';
import {ServiceCodeService} from '../../../service-code.service';
import {CacheService} from '../../../cache.service';
import {SimpleCacheService} from '../../../simple-cache.service';
import {escapeRegExp} from 'tslint/lib/utils';


@Component({
  selector: 'app-cti-bar',
  templateUrl: './cti-bar.component.html',
  styleUrls: ['./cti-bar.component.scss']
})
export class CtiBarComponent implements OnInit {

  spui: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private events: EventDistributerService,
    private serviceCodeService: ServiceCodeService,
    private cache: CacheService,
    private simpleCache: SimpleCacheService
  ) {
    this.events = events;
  }

  ngOnInit() {

    if (this.activatedRoute.snapshot.paramMap.get('spui').length > 10) {
      console.log('spui parameter will be handled as conversation ID.');

      this.simpleCache.get(this.activatedRoute.snapshot.paramMap.get('spui')).then((res) => {

        if(res) {
          if (res.agentUrl) {
            this.events.emit({
              name: 'AgentUrl',
              data: {
                agentUrl: res.agentUrl
              }
            });
          }

          if (res.assistantUrl) {
            this.events.emit({
              name: 'AssistantUrl',
              data: {
                assistantUrl: res.assistantUrl
              }
            });
          }

          if (res.spui) {
            this.events.emit({
              name: 'Spui',
              data: {
                spui: res.spui
              }
            });
          }
        }



        if (res && res.agentUrl && res.assistantUrl && res.spui) {

          this.start(res.spui);

          this.events.emit({
            name: 'IncomingCall',
            data: {
              serviceCodeData: {
                spui: res.spui,
                conversationId: this.activatedRoute.snapshot.paramMap.get('spui'),
                agentUrl: this.replaceAll(res.agentUrl, 'amp;', ''),
                assistantUrl: this.replaceAll(res.assistantUrl, 'amp;', ''),
                botHistoryUrl : this.replaceAll(res.botHistoryUrl, 'amp;', ''),
              }
            }
          });

        } else {

          this.serviceCodeService.findServiceCodeByConversationId(this.activatedRoute.snapshot.paramMap.get('spui')).then((serviceCodeRes) => {

            if(!serviceCodeRes) {
              console.warn('Unable to get SPUI from conversation id %s', this.activatedRoute.snapshot.paramMap.get('spui'));
              return;
            }

            this.start(serviceCodeRes.spui);
            this.events.emit({
              name: 'IncomingCall',
              data: {
                serviceCodeData: serviceCodeRes
              }
            });

          });
        }

      });


    } else {
      this.start(this.activatedRoute.snapshot.paramMap.get('spui'));
    }

  }

  start(spui: string): void {

    this.events.init(spui);
    this.events.listen().subscribe(event => {
      if (event.channel === 'TransferCall - ' + spui) {

        this.cache.add('InteractionEvent', event.message);

        this.events.emit({
          name: 'IncomingCall',
          data: event.message
        });
      }
    });
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }

}
