import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class WatsonDiscoveryService {

  private queryUrl: string;

  constructor(
    private http: HttpClient,
  ) {

  }

  query(text: string): Promise<any> {

    return new Promise((resolve, reject) => {

     this.http.get('https://utilities1.gointeract.io/interact_ai/api/WatsonDiscoveries/query', {
       params: {
         //environmentId: '8224988f-044f-4817-944f-ca62c3c311cd',
         //collectionId: 'fe9ba82e-a30d-4f7e-8706-76235c2f07e5',
         //username: '13b95494-0632-490d-aa32-1230ad5376e6',
         //password: 'l5qrTVbzgcI0',

         environmentId: '77c8cdb5-4331-4fa4-8696-61f185e6a272',
         collectionId: '546ff05c-132c-48d7-b67a-bad4b1c8f064',
         apiToken: '5Am5NjZahD08y4_8nF6TDWqdHSqyfRTp-FfabFTPW1jN',
         url: 'https://gateway.watsonplatform.net/discovery/api',
         queryText: text
       }
     }).subscribe((next: any) => {
       resolve(next);
     }, (err) => {
       reject(err);
     });

    });

  }


}
