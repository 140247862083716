import {Component, OnInit} from '@angular/core';
import {EventDistributerService} from '../../../event-distributer.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss'],
})
export class ToolBarComponent implements OnInit {

  private active: string;

  toolbarItems = [{
    name: 'journey',
    icon: 'history',
    toolTip: 'Customer Journey',
    active: false,
    highlight: false,
    highlightColor: ''
  }, {
    name: 'context',
    icon: 'clear_all',
    toolTip: 'Context',
    active: false,
    highlight: false,
    highlightColor: ''
  }, {
    name: 'assistance',
    icon: 'headset_mic',
    toolTip: 'Assistance',
    active: false,
    highlight: false,
    highlightColor: ''
  }, {
    name: 'knowledge',
    icon: 'question_answer',
    toolTip: 'Knowledge',
    active: false,
    highlight: false,
    highlightColor: ''
  }, {
    name: 'notes',
    icon: 'notes',
    toolTip: 'Notes',
    active: false,
    highlight: false,
    highlightColor: ''
  },];


  constructor(
    private events: EventDistributerService,
  ) {

  }

  ngOnInit() {

    this.events.listen().subscribe((event) => {
      if (event.name === 'IncomingCall') {
        this.buttonClicked('assistance');
      }

      if (event.name === 'highlight') {
        // change color
        this.toolbarItems.map((item) => {

          if (item.name === event.button) {
            item.highlight = true;

            setTimeout(() => {
              this.highLightButton(item.name);
            }, 50);

          }
        });
      }

    });

  }

  highLightButton(button: string) {
    const found = this.toolbarItems.filter((item) => {
      return item.name === button;
    })[0];

    if (found.highlight) {

      if (found.highlightColor === '') {
        found.highlightColor = 'primary';
      } else {
        found.highlightColor = '';
      }

      setTimeout(() => {
        this.highLightButton(button);
      }, 750);

    }

  }


  buttonClicked(event: string): void {

    this.events.emit({
      name: event + 'Clicked',
    });

    this.toolbarItems.map((item) => {

      if (item.name === event) {
        if (item.active) {
          this.events.emit({
            name: 'collapse'
          });
          item.active = false;
        } else {
          this.events.emit({
            name: 'expand'
          });
          item.active = true;
          item.highlight = false;
        }
      } else {
        item.active = false;
      }
    });

  }


  color(button: string): string {

    const found = this.toolbarItems.filter((item) => {
      return item.name === button;
    })[0];

    if (found.active) {
      return 'accent';
    } else if (found.highlight) {
      return found.highlightColor;
    }

  }

}
